$primary-main: #2E3192;
$primary-light: #8283BE;
$primary-dark: #282761;
$secondary-main: #0E0E2C;
$secondary-light: #6E6E80;
$secondary-dark: #3E3E56;
$border-gray: #d0d7de;

$success-main: #58A10E;
$error-main: #ED7A56;
$warning-main: #FAAF6D;

$header-bg: #24292f;

$light-gray: #6D6D6D;

.input-box:hover {
    border: 1px solid black
}

.popup-menu-container {
    height: 100%;
    position: relative;

    .profile-icon {
        height: 16px;
        width: 16px;
        padding: 0.5rem;
        background: $primary-main;
        color: white;
        font-weight: 500;
        font-size: 10;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .popup-menu {
        position: absolute;
        height: fit-content;
        bottom: -7rem;
        right: 0;
        width: fit-content;
        min-width: 100px;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 1px 2px 0px rgba(60,64,67,.30),0px 1px 3px 1px rgba(60,64,67,.15);
        border-radius: 6px;
    
        .popup-menu-header {
            height: fit-content;
            width: 100%;
            padding: 1rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            box-sizing: border-box;
    
            .profile-info {
                display: flex;
                flex-direction: column;
                padding: 0 0.5rem;
                
                h5 {
                    font-size: 16px;
                    font-weight: 600;
                }
    
                p {
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }
    
        .popup-menu-footer {
            border-top: 1px solid $border-gray;
            height: fit-content;
            padding: 0.5rem 1rem;
            text-align: left;
            width: 100%;
            box-sizing: border-box;
            border-radius: 0 0 6px 6px;
            font-size: 16px;
            font-weight: 600;
            color: $primary-main;

            &:hover {
                color: white;
                background: $primary-main;
                cursor: pointer;
            }
        }
    }
}
